.contextMenu {
    position: absolute;
    visibility: hidden;
    top: 125px;
    left: 600px;
    width: 160px;
    height: 40px;
    padding: 5px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 16px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.05) 0px 9px 28px 8px;
}

.contextMenuItem {
    padding: 5px;
    border-radius: 8px;
    cursor: pointer;
}

.contextMenuItem:hover {
    background-color: #f2f2f2;
}