/* GENERAL */
* {
    font-family: 'Poppins', sans-serif;
}

html, body, #root, #site, #site>* {
    font-size: 16px;
}

html, body, #root {
    height: 100%;
}

#site {
    height: calc(100% - 74px);
}

.ag-header-cell-label, .ag-header-group-cell-label {
    justify-content: center;
}

hr{
    width: 85%!important;
    color: rgba(5, 5, 5, 0.06);
}

/* ANTD CUSTOM */

.ant-tabs-content {
    height: 100%;
}

.ant-menu-horizontal {
    border-bottom: none;
}

/* AGGRID CUSTOM */

.ag-group-value {
    display: flex;
}

.ag-theme-alpine {
    --ag-font-size: 12px !important;
    --ag-font-family: 'Poppins', sans-serif !important;
  }

/*red (*) for required*/
.ant-form-item-required::before {
    display: none !important;
}

.ant-collapse-header {
    padding: 0!important;
}

.ant-select-item {
    min-height: 0px!important;
}

.ant-select-item-option-grouped {
    padding-left: 12px!important;
}

.ant-card-actions > li > span {
    cursor: default !important;
}

.ant-card-actions > li > span:hover {
    color: rgba(0, 0, 0, 0.45) !important;
}

/* MyCUSTOM */

.expand-icon {
    transition: all 0.3s ease-out;
    width: 20px !important;
    height: 20px !important;
}

/* GENERALS */

.red-txt {
    color: red !important;
}

.bld-txt {
    font-weight: bold !important;
}

.span-red {
    color: red;
}

.span-bold {
    font-weight: 900;
}

.div-center {
    text-align: center;
}

.bdg {
    color: white;
    border-radius: 10rem;
    font-weight: 700;
    border: none;
}

.bdg-success {
    background-color: green;
}

.bdg-error {
    background-color: red;
}

.is-disable {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}

/* Change SVG color */

.info-svg path {
    fill: #0b6fba !important;
}

/* TABBAR */

.tb-container {
    min-width: 8%;
    margin: 0 15px;
}

.tb-svg-blue path {
    fill: #0b6fba!important;
}

.tb-svg-white path {
    fill: #fff!important;
}

/* Modal */

.notificationsModal .ant-modal-content {
    max-height: calc(100vh - 45px) !important;
}

/* SPECIFIC COLORS */

.bg-grey {
    background-color: #bdbdbd !important;
}

.bg-green {
    background-color: #52c41a !important;
}

.bg-orange {
    background-color: #faad14 !important;
}

.bg-red {
    background-color: #ff4d4f !important;
}

.clr-primary {
    color: #0b6fba;
}

.bg-clr-primary {
    background-color: #0b6fba;
}

/* SPECIFIC ROUND */

.rad-50 {
    border-radius: 50%;
}