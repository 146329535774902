.divDisabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed!important;
    /* pointer-events: none!important; */
}

.divDisabled:hover {
    color: rgba(0, 0, 0, 0.25)!important;
}

/* REQUEST FORM */

.requestFormRow div {
    margin-bottom: 0px!important;
}

/* LEFT PANEL OPTION MENU */

.vertical-checkbox-group {
    display: flex;
    flex-direction: column;
}

.vertical-checkbox-group > label {
    margin-inline-start: 0 !important;
}