.rec-navbar-container * a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.65) !important;
}

.rec-navbar-container * a:hover {
    color: #fff !important;
}

.rec-navbar-container * a[class="active"] {
    color: #fff !important;
}

.logo{
    padding: 0.4rem;
    margin: 7px 0;
    border-radius: 50%;
    background-color: #fff;
}

@media (min-width: 992px) {
    .li-nav-sd-button {
        display: none;
    }
}

@media (max-width: 992px) {
    .li-nav {
        display: none !important;
    }
}