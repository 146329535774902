/* FORM */

.ant-picker-input >input:placeholder-shown {
  text-align: center;
}

.ant-picker .ant-picker-input >input {
  text-align: center;
}

.ant-picker-panel-container {
  padding: 1rem;
}

.ant-select-multiple.ant-select-lg .ant-select-selector, .ant-select-single .ant-select-selector {
  border-radius: 0.2rem !important;
}

.ant-input-affix-wrapper {
  border-radius: 0.2rem;
}

.ant-picker {
  border-radius: 0.2rem;
}

.ant-btn.ant-btn-lg, .ant-btn {
  border-radius: 0.2rem;
}

/* PROGRESS */

.ant-progress-steps-item {
    margin-right: 0 !important;
}